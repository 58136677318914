import { NgModule } from '@angular/core';
import { ClampDirective } from './clamp.directive';

@NgModule({
  imports: [
  ],
  declarations: [ClampDirective],
  exports: [ClampDirective]
})
export class ClampyModule { }
